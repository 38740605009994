import './App.css';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";
import React, { useEffect, useState } from 'react';

import AudioPlayer from './AudioPlayer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { getStories, userPlayed } from './messages';
// import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { fakeData, hasBackend } from './config';

function StoryList({storyList}) {
  const playInline = true;
  const [audioData, setAudioData] = useState(null);

  function playClicked(storyId) {
    if (playInline) {
      setAudioData(storyList.find(story => story._id === storyId));
      //return true;
    } else {
      //return true;
    }
  }

  return (
    <div id="StoryList" style={{padding: '10px'}}>
      <img src="CuriosityAndHope.png" alt="Curiosity and Hope" style={{width: '100%', maxWidth: '300px', marginLeft: 'auto', marginRight: 'auto', display: 'block'}}></img>
      {storyList && <div>
        {storyList.map((story, index) => (
          <Card variant="outlined" key={index} sx={{ maxWidth: '100%', marginTop: '10px' }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
              {story.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {story.text.substring(0, 200)}...
              </Typography>
              <Typography>
              { 
                playInline && audioData && (audioData._id === story._id) && <AudioPlayer audioData={audioData} setAudioData={setAudioData}></AudioPlayer>
              }
              </Typography>
              </CardContent>
              <CardActions>
              
                {/* <Button size="small"><a onClick={() => playClicked(story._id)} href={playInline ? '#' : `/play/${story._id}`}>Play</a></Button> */}
                {
                  !(playInline && audioData && (audioData._id === story._id)) && <Button onClick={() => playClicked(story._id)} size="small">Play</Button>
                }
              
              <Button size="small"><a href={story.url} target="_lbank">Read</a> </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    }
    </div>
  );
}

function PlayStory({ storyList }) {
  const { storyId } = useParams();
  console.log(storyId);
  const story = storyList.find(story => story._id === storyId);
  console.log(storyList);
  const domain = new URL(story.url).hostname;

  // useEffect(() => {
  //   if (hasBackend) userPlayed(storyId);
  // });
  
  return (
    <div id="PlayStory">
      <Card variant="outlined" sx={{ maxWidth: '100%', marginTop: '10px' }}>
        <Button variant="outlined" startIcon={<ArrowBackIcon />}><a href="/">Back</a></Button>
        <h1>{story.title}</h1>
        <p style={{marginLeft: 10}}>from {domain}</p>
        {/* <p className="story-text">{story.text}</p> */}
        <AudioPlayer audioData={story}></AudioPlayer>
        <Button size="small"><a href={story.url} target="_lbank">Read the Story</a> </Button>
      </Card>
    </div>
  );
}

function App() {
  const [storyList, setStoryList] = useState(null);

  useEffect(() => {
    const fetchStories = async () => {
      try {
        const topic = null;
        
        const response = hasBackend ? (await getStories(topic)) : fakeData

        setStoryList(response.data);
      } catch (error) {
        console.error('Error fetching story list:', error);
      }
    };

    fetchStories();
  }, []);


  return (
    <div>
      {storyList && (<BrowserRouter>
        <Routes>
          <Route path="/" element={<StoryList storyList={storyList}/>} />
          <Route path="/play/:storyId" element={<PlayStory storyList={storyList}/>} />
        </Routes>
      </BrowserRouter>)
      } 
      {!storyList && <h1>Loading</h1>}
    </div>
  );
}

export default App;
