import axios from 'axios';

async function getStories(topic) {
  const storiesUrl = topic ? `/get-stories/${topic}` : '/get-stories';
  return axios.get(storiesUrl);
}

async function userPlayed(storyId) {
  return axios.post(`/user-played/${storyId}`);
}


export { getStories, userPlayed };