// import ReactPlayer from 'react-player';

//import {Player} from 'react-simple-player';
import ReactAudioPlayer from 'react-audio-player';
import React, { useState } from 'react';
import { s3Url } from './config';


function AudioPlayer({ audioData, setAudioData }) {
  const [isLoading, setIsLoading] = useState(true);

  const handlePlay = () => {
    setIsLoading(false);
  };

  const stopPlay = () => {
    setAudioData(null);
  }

  return (
    <div id="player" onBlur={stopPlay}>
      {isLoading && (
        <div>Loading...</div> // Display a loading spinner or other indicator
      )}
      <ReactAudioPlayer src={`${s3Url}/${audioData.audio_file}`} autoPlay controls onCanPlayThrough={handlePlay} />
    </div>

  );
}

export default AudioPlayer;